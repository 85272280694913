import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import QuoteGenerator from "../tools/QuoteGenerator"

export default function QuoteTool() {
  return (
    <Layout>
      <SEO title="Social Quote Generator" />
      <QuoteGenerator />
    </Layout>
  )
}

import React, { useState } from "react"
import {
  SimpleGrid,
  Box,
  Button,
  Stack,
  Alert,
  AlertIcon,
  Text,
} from "@chakra-ui/core"
import { FiTwitter } from "react-icons/fi"
import domtoimage from "dom-to-image"
import { saveAs } from "file-saver"

import ConfigForm from "./ConfigForm"

const Twitter = props => (
  <Box as={FiTwitter} size="16px" color="white" {...props} />
)

export default function QuoteGenerator() {
  const [textColor, setTextColor] = useState("#FFF")
  const [captionTextColor, setCaptionTextColor] = useState("#FFF")
  const [backgroundColor, setBackgroundColor] = useState("#3D5A81")
  const [topText, setTopText] = useState("Top Text")
  const [captionText, setCaptionText] = useState("Caption Text")
  const [width, setWidth] = useState(600)
  const [height, setHeight] = useState(337.5)
  const [errorMessage, setErrorMessage] = useState(null)

  const quoteElement = React.createRef()

  const handleSave = async () => {
    if (errorMessage) setErrorMessage(null)

    try {
      const blob = await domtoimage.toBlob(quoteElement.current)
      saveAs(blob, "quote.png")
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const handleTweet = () => {
    alert("Not supported yet, sorry!")
  }

  const configFormProps = {
    textColor,
    setTextColor,
    backgroundColor,
    setBackgroundColor,
    captionTextColor,
    setCaptionTextColor,
    width,
    setWidth,
    height,
    setHeight,
    topText,
    setTopText,
    captionText,
    setCaptionText,
  }

  return (
    <SimpleGrid columns={2} spacing={8}>
      <Stack spacing={8}>
        <ConfigForm {...configFormProps} />
        {errorMessage && (
          <Alert status="error">
            <AlertIcon />
            {errorMessage}
          </Alert>
        )}
      </Stack>
      <Stack spacing={8}>
        <Box
          ref={quoteElement}
          backgroundColor={backgroundColor}
          width={width}
          height={height}
          padding={4}
        >
          <Text color={textColor} fontSize="4xl">
            {topText}
          </Text>
          <Text color={captionTextColor} fontSize="2xl">
            {captionText}
          </Text>
        </Box>
        <Stack spacing={4} isInline>
          <Button
            variant="solid"
            color="white"
            leftIcon="download"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant="solid"
            color="white"
            leftIcon={Twitter}
            onClick={handleTweet}
          >
            Tweet
          </Button>
        </Stack>
      </Stack>
    </SimpleGrid>
  )
}

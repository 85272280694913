import React from "react"
import { ChromePicker } from "react-color"
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Flex,
} from "@chakra-ui/core"
import { MdColorLens } from "react-icons/md"
import invert from "invert-color"

const ColorpickerIcon = props => (
  <Box as={MdColorLens} size="24px" color="white" {...props} />
)

export default function ConfirmForm({
  textColor,
  setTextColor,
  captionTextColor,
  setCaptionTextColor,
  backgroundColor,
  setBackgroundColor,
  width,
  setWidth,
  height,
  setHeight,
  topText,
  setTopText,
  captionText,
  setCaptionText,
}) {
  return (
    <form onSubmit={event => event.preventDefault()}>
      <Stack spacing={8}>
        <FormControl>
          <FormLabel color="white" htmlFor="topText">
            Top Text
          </FormLabel>
          <Flex>
            <Popover>
              <PopoverTrigger>
                <IconButton
                  marginRight={2}
                  backgroundColor={textColor}
                  icon={() => (
                    <ColorpickerIcon color={invert(textColor, true)} />
                  )}
                  aria-label="Select text color"
                />
              </PopoverTrigger>
              <PopoverContent paddingBottom={2} zIndex={4}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader color="white">Text Color</PopoverHeader>
                <PopoverBody>
                  <ChromePicker
                    color={textColor}
                    disableAlpha={true}
                    onChange={({ hex }) => setTextColor(hex)}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Input
              type="text"
              id="topText"
              value={topText}
              color="white"
              onChange={event => setTopText(event.target.value)}
            />
          </Flex>
        </FormControl>

        <FormControl>
          <FormLabel color="white" htmlFor="captionText">
            Caption Text
          </FormLabel>
          <Flex>
            <Popover>
              <PopoverTrigger>
                <IconButton
                  marginRight={2}
                  backgroundColor={captionTextColor}
                  icon={() => (
                    <ColorpickerIcon color={invert(captionTextColor, true)} />
                  )}
                  aria-label="Select caption text color"
                />
              </PopoverTrigger>
              <PopoverContent paddingBottom={2} zIndex={4}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader color="white">Caption Text Color</PopoverHeader>
                <PopoverBody>
                  <ChromePicker
                    color={captionTextColor}
                    disableAlpha={true}
                    onChange={({ hex }) => setCaptionTextColor(hex)}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Input
              type="text"
              id="captionText"
              value={captionText}
              color="white"
              onChange={event => setCaptionText(event.target.value)}
            />
          </Flex>
        </FormControl>
      </Stack>
    </form>
  )
}
